<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <a-card>
                <a-row :gutter="10">
                    <a-col :span="2">
                        <a-date-picker
                            mode="year"
                            format="YYYY"
                            :allowClear="false"
                            :value="currentYear"
                            @openChange="isOpen = true"
                            :open="isOpen"
                            style="width: 100%;"
                            @panelChange="yearChange"
                        />
                    </a-col>
                    <a-col :span="22">
                        <a-divider type="vertical" v-if="isGranted('sl_sum_salary_excel')" />
                        <a-button type="primary" @click="Export()" v-if="isGranted('sl_sum_salary_excel')">
                            <a-icon type="download" />
                            <span>导出</span>
                        </a-button>
                    </a-col>
                </a-row>
                <a-row :gutter="[20, 10]">
                    <a-col>
                        <a-table
                            :columns="columns"
                            :pagination="false"
                            :scroll="{ y: scroll_y, x: scroll_x }"
                            :rowKey="(data, index) => index"
                            :dataSource="tableData"
                        >
                                <span v-for="(item, index) in columns" slot-scope="text, record" :slot="item.dataIndex" :key="index">
                                    <span v-if="item.scopedSlots.customRender == 'comPany'">
                                        {{ record.comPany }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'monthSumSaray1'">
                                        {{ record.data[0].monthSumSaray }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'count1'">
                                        {{ record.data[0].count }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'monthSumSaray2'">
                                        {{ record.data[1].monthSumSaray }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'count2'">
                                        {{ record.data[1].count }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'monthSumSaray3'">
                                        {{ record.data[2].monthSumSaray }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'count3'">
                                        {{ record.data[2].count }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'monthSumSaray4'">
                                        {{ record.data[3].monthSumSaray }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'count4'">
                                        {{ record.data[3].count }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'monthSumSaray5'">
                                        {{ record.data[4].monthSumSaray }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'count5'">
                                        {{ record.data[4].count }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'monthSumSaray6'">
                                        {{ record.data[5].monthSumSaray }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'count6'">
                                        {{ record.data[5].count }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'monthSumSaray7'">
                                        {{ record.data[6].monthSumSaray }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'count7'">
                                        {{ record.data[6].count }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'monthSumSaray8'">
                                        {{ record.data[7].monthSumSaray }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'count8'">
                                        {{ record.data[7].count }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'monthSumSaray9'">
                                        {{ record.data[8].monthSumSaray }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'count9'">
                                        {{ record.data[8].count }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'monthSumSaray10'">
                                        {{ record.data[9].monthSumSaray }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'count10'">
                                        {{ record.data[9].count }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'monthSumSaray11'">
                                        {{ record.data[10].monthSumSaray }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'count11'">
                                        {{ record.data[10].count }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'monthSumSaray12'">
                                        {{ record.data[11].monthSumSaray }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'count12'">
                                        {{ record.data[11].count }}
                                    </span>
                                    <span v-if="item.scopedSlots.customRender == 'yearSumSalary'">
                                        {{ record.yearSumSalary }}
                                    </span>
                                </span>
                        </a-table>
                    </a-col>
                </a-row>
            </a-card>
            <div
                v-if="isOpen"
                :style="{
                    height: windowHeight + 'px',
                    position: 'absolute',
                    top: '0px',
                    width: '100%',
                }"
                @click="isOpen = false"
            ></div>
        </a-spin>
    </a-config-provider>
</template>

<script>
import { AppComponentBase } from '../../../../shared/component-base';
import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
import moment from 'moment';
import { SalaryReportServiceProxy } from '../../../../shared/service-proxies';
import { fileDownloadService } from '../../../../shared/utils';

let monthColums = [];

export default {
    name: 'sum-salary',
    mixins: [AppComponentBase],
    data() {
        return {
            zh_CN,
            //表格上方间隔
            defaultTop: -80,
            isOpen: false,
            currentYear: undefined,
            // 用户表格
            columns: [],
            tableData: [],
            scroll_x: 300,
        };
    },
    computed: {
        windowHeight() {
            return window.innerHeight - 150;
        },
    },
    created() {
        this._salaryReportServiceProxy = new SalaryReportServiceProxy(this.$apiUrl, this.$api);
        this._fileDownloadService = fileDownloadService;
    },
    mounted() {
        this.currentYear = moment();
        this.getData();
    },
    methods: {
        getData() {
            this.loading = true;
            this._salaryReportServiceProxy
                .getSumSalaryByYear(this.currentYear.year())
                .finally(() => {
                    this.loading = false;
                })
                .then(async (res) => {
                    this.tableData = res;
                    await this.monthColumsInit();
                });
        },
        /**
         * 初始化表头
         */
        monthColumsInit() {
            return new  Promise(resolve => {
                this.columns = [];
                this.columns.push({
                    title: '公司',
                    dataIndex: 'comPany',
                    ellipsis: true,
                    fixed: 'left',
                    width: 150,
                    align: 'center',
                    scopedSlots: { customRender: 'comPany' },
                });
                if (this.tableData.length > 0) {
                    let data = this.tableData[0].data;
                    this.scroll_x = this.scroll_x + this.tableData[0].data.length * 200;
                    data.forEach((item, index) => {
                        this.columns.push({
                            title: index + 1 + '月份工资总额',
                            dataIndex: 'monthSumSaray' + (index + 1),
                            ellipsis: true,
                            width: 150,
                            align: 'center',
                            scopedSlots: { customRender: 'monthSumSaray' + (index + 1) },
                        });
                        this.columns.push({
                            title: index + 1 + '月份总人数',
                            dataIndex: 'count' + (index + 1),
                            ellipsis: true,
                            width: 150,
                            align: 'center',
                            scopedSlots: { customRender: 'count' + (index + 1) },
                        });
                    });
                }
                this.columns.push({
                    title: '全年工资总额',
                    dataIndex: 'yearSumSalary',
                    sorter: false,
                    width: 150,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'yearSumSalary' },
                });
                resolve();
            })
        },
        Export() {
            if (this.tableData.length <= 0) {
                return abp.message.warn(this.l('NoData'));
            }
            this.loading = true;
            this._salaryReportServiceProxy
                .getSumSalaryByYearToExcel(this.currentYear.year())
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this._fileDownloadService.downloadTempFile(res);
                });
        },
        //年选择器改变
        yearChange(e) {
            this.isOpen = false;
            this.currentYear = e;
            this.getData();
        },
    },
};
</script>

<style scoped></style>
